/* global.css */

/* Import Tailwind CSS styles */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import the Nunito font with ExtraBold (800) weight */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

/* Override Tailwind CSS styles */
body {
  font-family: 'Nunito', sans-serif !important;
  font-weight: 700 !important; /* ExtraBold */
}
