.about-container {
  min-height: 100vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 16px; /* Adjust padding as needed */
}

.introduction,
.contact-section {
  max-width: 800px;
  text-align: center;
  margin-bottom: 40px;
}
.contact-link{
  color: #1E3E62;

}

h4 {
  font-size: 24px;
  color: #1E3E62;
  
}

h7 {
  font-size: 14px;
  color: #000000;
  
}

p {
  padding-top: 20px;
  font-size: 16px;
  font-style: italic;
  padding: auto;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #1E3E62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0f2c44; /* Darker shade on hover */
}
