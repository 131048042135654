/* Main container for the literature list */
/* .literature-container {
  font-family: 'Nunito', sans-serif !important;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  } */
  .literature-container {
    font-family: 'Nunito', sans-serif !important;
    margin: 0;
    padding: 20px;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Section title */
  .literature-title {
    text-align: center;
    color: #0f2c44;
    font-size: 1.8rem;
    margin: 20px;
    padding-bottom: 20px;
  }
  
  /* List container */
  /* .literature-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  } */
  /* .literature-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
  } */
  .literature-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    gap: 20px;
  }
  @media (max-width: 1024px) {
    .literature-list {
      grid-template-columns: repeat(2, minmax(250px, 1fr)); /* 2 columns for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .literature-list {
      grid-template-columns: 1fr; /* 1 column for small screens */
    }
  }
  
  /* Individual card styles */
  .literature-card {
    background: #ffffff;
    border-radius: 8px;
    padding: 25px 30px;
    border: 1px solid #ddd;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .literature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Item name */
  .literature-name {
    font-size: 1.4rem;
    color: #222;
    margin: 0 0 10px;
  }
  
  /* Item description */
  .literature-description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Nunito', sans-serif !important;
    
  }
  
  /* Link styles */
  .literature-link {
    display: inline-block;
    font-size: 0.8rem;
    font-family: 'Nunito', sans-serif !important;
    padding: 5px;
    color: #fff;
    background-color: #203151;;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.2s ease;
  }
  
  .literature-link:hover {
    background: #121921;
  }

  .toggle-button{
    color: #203151;
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    padding-top: 0.3rem;
    cursor: pointer;
  }
  .toggle-text {
    color: #007bff;
    cursor: pointer;
    /* text-decoration: underline; */
  }
  
  .toggle-text:hover {
    color: #0056b3;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 10px 15px;
    margin: 0 5px;
    background-color: #203151;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 16px;
    margin: 0 10px;
  }
  