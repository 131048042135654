/* Reset and base styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #1E3E62;
  font-size: 1.125rem; /* 18px */
}
.logo {
  width: 4%; /* Increase the size of the logo on mobile */
}
.navlinkhome {
  font-size: 18px; /* Set font size */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Set font weight */
  color: #1E3E62; /* Default color */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}
.navlinkhome:hover {
  color: #84bdff; /* Change color on hover */
}



/* Navbar */
.navbar {
  background-color: aliceblue;
  padding: 0.625rem 1.25rem; /* 10px 20px */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem; /* 40px */
  min-height: 4.375rem; /* 70px */
}

/* Logo */
.logo {
  font-size: 1.875rem; /* 30px */
  font-family: Arial, Helvetica, sans-serif;
}

/* Navigation Menu */
.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.75rem; /* 60px */
}

.navLink {
  font-size: 1.125rem; /* 18px */
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #84bdff;
}

/* Authentication Links */
.authContainer {
  margin-left: auto;
  display: flex;
}

.authLink {
  color: #1E3E62;;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  margin-left: 1rem;
}

.authLink:hover {
  color: #8de6ff;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  background-color: #1E3E62;
  width: 1.25rem; /* 20px */
  height: 0.1875rem; /* 3px */
  display: block;
  margin: 0.3125rem; /* 5px */
  transition: 0.3s ease-in-out;
}

/* Active State for Hamburger */
.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(1) {
  transform: translateY(0.5rem) rotate(45deg); /* 8px */
}

.hamburger.active .bar:nth-child(3) {
  transform: translateY(-0.5rem) rotate(-45deg); /* -8px */
}

/* Mobile Styles */
@media screen and (max-width: 780px) {
  a {
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  /* Display Hamburger */
  .hamburger {
    display: block;
    color: #1E3E62;
  }
  .logo {
    width: 8%; /* Increase the size of the logo on mobile */
  }
  .navlinkhome {
    font-size: 14px; /* Set font size */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Set font weight */
    color: #1E3E62; /* Default color */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  .navlinkhome:hover {
    color: #84bdff; /* Change color on hover */
  }
  /* Adjust Navigation Menu for Mobile */
  .navMenu {
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 4.375rem; /* 70px */
    left: -100%;
    text-align: start;
    width: 100%;
    transition: left 0.7s ease-in-out;
    background-color: #1E3E62;
    padding: 0.8rem; /* lesser than 10px */
  }

  .navMenu.active {
    left: 0;
  }

  .navMenu li {
    margin: 0.6rem 0; /* 16px */
  }

  /* Responsive Font Sizes for Mobile */
  .logo {
    font-size: 4rem; /* 24px */
  }

  .navLink {
    font-size: 1rem; /* 16px */
  }



  /* Adjust Auth Container */
  .authContainer {
    margin-left: 0;
    margin-top: 1rem;
  }
}
