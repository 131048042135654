.quiz-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Nunito', sans-serif !important;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .quiz-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .language-select {
    font-size: 16px;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .question-container {
    margin-top: 20px;
  }
  
  .question-word {
    font-size: 20px;
    color: #0f2c44;
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
  }
  
  .option-button {
    padding: 10px;
    font-size: 18px;
    color: white;
    background-color: #0f2c44;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .option-button:hover {
    background-color: #1E3E62;
  }
  
  .question-progress {
    font-size: 14px;
    color: #333;
  }
  
  .results-container {
    margin-top: 20px;
  }
  
  .answers-review {
    list-style-type: none;
    padding: 0;
  }
  
  .answers-review li {
    text-align: left;
    margin: 10px 0;
  }
  
  .home-button {
    padding: 10px 20px;
    background-color: #0f2c44;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .home-button:hover {
    background-color: #313C52;
  }
  